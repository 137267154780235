@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

* {
  -ms-overflow-style: none;
}
* ::-webkit-scrollbar {
  display: none;
}

html,
body {
  @apply bg-gray-50 m-0 p-0;
  font-family: 'Titillium Web', sans-serif;
  box-sizing: border-box;
}

p,
footer {
  @apply cursor-default;
}

footer {
  @apply text-sm;
  padding: 1px 4px !important;
}

#app-root {
  @apply flex flex-col justify-between h-screen min-h-screen relative;
  min-height: 600px;
}

main > * {
  @apply row-span-3 px-28 pt-10 pb-10 overflow-y-auto h-screen;
}

.nav-list {
  @apply flex flex-col justify-center items-start text-lg;
}

.nav-list .nav-item {
  @apply text-center my-1;
}
.nav-item span:hover {
  @apply opacity-100;
}

.nav-item span:after {
  content: '';
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  @apply border-b-2 border-indigo-600 block;
}

.nav-item span:hover:after {
  transform: scaleX(1);
}

span.active-navlink:after {
  transform: scaleX(1);
}
.active-navlink {
  @apply font-semibold;
}
.inactive-navlink {
  @apply opacity-70;
}

#home {
  @apply flex flex-col justify-center;
}

#profile-img {
  @apply rounded-full border-2 mx-auto;
}

#self-intro {
  @apply absolute w-fit animate-pulse text-center bg-white px-2 py-2 text-black ml-14;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  top: 0;
  left: 50%;
}

#toolbar {
  @apply flex flex-col justify-between items-center absolute left-10 bottom-11;
}

#toolbar > a,
#toolbar > span,
#toolbar > label,
#nav-action-bar label {
  @apply text-3xl hover:text-indigo-500 my-4 cursor-pointer;
}
#toolbar > div {
  @apply text-3xl my-2;
}

#nav-action-bar {
  @apply flex flex-col justify-between items-center absolute right-10 bottom-16 cursor-pointer;
}
#nav-action-bar ul li.dark:hover {
  background-color: #333333;
}

#home-badge {
  font-family: 'VT323', monospace;
}

#admin-badge {
  @apply left-10;
}
#admin-badge > span {
  @apply hidden;
}

#dashboard {
  @apply py-4 px-20 flex flex-col;
}

#version {
  @apply max-w-7xl mx-auto;
}

#about > article {
  @apply shadow-md max-w-5xl mx-auto text-justify p-8 text-lg h-full flex flex-col justify-center;
  box-shadow: none !important;
}

#project-card-icons > * {
  @apply mr-6;
}

.login-label {
  @apply w-24;
}

#form {
  @apply flex flex-col w-full;
}

.form-title {
  @apply text-xl flex justify-center items-center mb-2;
}

.contact-label,
.form-div {
  @apply flex justify-start items-center mb-2;
}

.contact-label {
  @apply w-20;
}

.profile-label {
  @apply w-56;
}

.contact-input,
.login-input {
  @apply border-b-2 p-1 font-light w-full bg-inherit;
}

.contact-input:focus,
.login-input:focus {
  @apply border-indigo-600;
  outline: none;
}

.contact-textarea {
  @apply border-2 mb-2 p-1 bg-inherit;
}
.contact-textarea:focus {
  @apply border-indigo-600;
  outline: none;
}

.expand-underline:after {
  content: '';
  display: block;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  @apply border-b-2 border-indigo-600;
}
.expand-underline:hover:after {
  transform: scaleX(1);
}

.message-detail-content {
  @apply flex flex-col pt-4 justify-start items-start overflow-y-scroll;
  max-height: 60vh;
}
.message-detail-content > div {
  @apply flex my-1 mx-4;
}
.message-detail-content > div:last-child {
  @apply mx-auto;
}
.message-detail-content > div > button {
  @apply mx-4 my-4 px-3 py-2 font-semibold text-white bg-gray-600 hover:opacity-70;
}
.message-detail-content > div > span:first-child {
  @apply flex-none w-20;
}
.message-detail-content > div > span:last-child {
  @apply flex-1 text-justify;
}

#dashboard-messages {
  @apply overflow-x-auto w-full pb-8;
}
#dashboard-messages table > tbody > tr > td {
  @apply border-b-2 py-2;
}

#page-401,
#page-404 {
  @apply flex flex-col justify-center items-center;
}

.g-recaptcha {
  transform: scale(0.84);
  transform-origin: 0 0;
}

@media (max-width: 480px) {
  .g-recaptcha {
    transform: scale(0.7);
  }
}

@media (max-width: 420px) {
  .g-recaptcha {
    transform: scale(0.6);
  }
}

.btn-customized {
  @apply w-full px-3 py-2 my-6 font-semibold text-white bg-indigo-600 hover:opacity-70;
}

#alert-message {
  @apply absolute h-fit cursor-default z-10 rounded-none;
  bottom: 0;
}

/* Small screen responsiveness */
@media (max-width: 768px) {
  .nav-list {
    @apply hidden;
  }

  #toolbar {
    @apply left-4 bottom-9;
  }
  #toolbar > label,
  #toolbar > a,
  #toolbar > div,
  #toolbar > span {
    @apply text-2xl;
  }

  #nav-action-bar {
    @apply right-4 bottom-14;
  }

  #home {
    @apply px-0 pt-0;
  }
  #home > div:last-child {
    @apply text-base;
  }

  #self-intro {
    @apply text-sm p-1 w-16 -top-12;
  }

  #about {
    @apply mx-14 my-0 px-0;
  }
  #about > article {
    @apply px-0 py-0 text-base flex flex-col h-full justify-center;
    box-shadow: none !important;
  }

  #contact {
    @apply mx-10 my-0 px-0 pt-0 pb-0;
  }
  #contact > * {
    @apply p-4;
    box-shadow: none !important;
    border: none;
  }
  #contact form > div:last-child,
  #contact form > button {
    @apply my-2;
  }

  #dashboard-messages {
    @apply text-sm;
  }
  #dashboard-messages table > tbody > tr > td {
    @apply border-b-2 py-0;
  }
  .table-col-hide {
    @apply hidden;
  }

  #admin {
    @apply mx-8 my-0 px-2 pt-0 pb-0;
  }
  #admin > div {
    @apply p-4;
    box-shadow: none !important;
    border: none;
  }

  #profile {
    @apply mx-14 my-0 px-1;
  }
  #profile > div > div:last-child {
    @apply p-0;
    box-shadow: none !important;
    border: none;
  }

  #version {
    @apply mx-14 my-0 px-1;
  }
  #version > div {
    @apply px-0 mx-0;
  }

  footer {
    @apply py-1 text-xs;
  }
  footer > div > .copyright {
    @apply hidden;
  }

  #scroll-top {
    @apply px-14;
  }
  #scroll-top > div {
    @apply px-2;
  }

  #project-card {
    @apply my-8 py-6 grid grid-flow-row grid-cols-1 text-center border-dashed border-b-2 border-gray-500;
    box-shadow: none !important;
  }
  #project-card .media {
    @apply h-fit;
  }
  #project-card .media img {
    width: 100% !important;
  }
  #project-card .detail > div {
    @apply my-1;
  }
  #project-card .detail > div > span {
    @apply text-sm mx-1 my-0 px-1 py-0;
  }

  #page-401,
  #page-404 {
    @apply px-0;
  }
  #page-404 #snake-game {
    @apply hidden;
  }

  #admin-badge > span {
    @apply hidden;
  }
  #admin-badge {
    @apply left-4;
  }

  #dashboard {
    @apply py-0 px-0;
  }

  #dash-control {
    @apply mx-auto pt-4;
  }
  #dash-control > * {
    @apply px-2;
  }
}
